import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-menu',
  templateUrl: 'menu.component.html'
})
export class MenuComponent implements OnInit {

  @Input() item: any;
  @Input() accordion: any;

  data: any;
  mock: any;

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.menu()
      .subscribe((res: any) => {
        this.data = res.data;
      });
  }

}

