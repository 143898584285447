import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService, Channel } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-components-shared-featured-config',
  templateUrl: 'featured-config.component.html',
  providers: [DataService]
})
export class SharedFeaturedConfigComponent implements OnInit {

  @Input() item: any;
  @Input() key: string;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadBannerEvent = new EventEmitter<any>();

  courseSectionData: any;
  eventSectionData: any;
  eventSections: any;

  channelList$: Observable<Channel[]>;
  channelItems$: Observable<Channel[]>;

  constructor(
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadBanner() {
    this.reloadBannerEvent.emit(this.item);
  }

  getData() {
    this.dataService.cursosSessao().subscribe(res => {
      this.courseSectionData = res;
      console.log('getData: courseSectionData', this.courseSectionData)
    });

    this.dataService.eventoSessao()
      .subscribe(res => {
        this.eventSections = res;
        console.log('getData: eventSections', this.eventSections)
      });

    this.dataService.custom('event-sessao', 8).subscribe((res: any) => {
      this.eventSectionData = res.data['evento-sessao'];
      console.log('getData: eventSectionData', this.eventSectionData)
    });

    this.channelList$ = this.dataService.channels();
  }

  courseBySection(section: any) {
    if (!this.courseSectionData) {
      return;
    }
    const find = this.courseSectionData.find((item: any) => +item.session_id === +section);
    if (find) {
      return find.trail;
    }
  }

  eventBySection(section: any) {
    if (!this.eventSectionData) {
      return;
    }
    const filter = this.eventSectionData.filter((item: any) => +item.session_id === +section);
    if (filter.length > 0) {
      return filter;
    }
  }

  carouselItemChange(event,item) {
    delete(item.section);
    delete(item.id);
    delete(item.arquivo);
  }

  channelIDChange(value) {
    let type = this.item.type.split('-')[1];
    this.channelItems$ = this.dataService.channelItems(value,type);
  }

  channelItemIDChange(value,list) {
    let item = list.find(i => i.id == value);
    if (item) {
      this.item.item_id = item.id;
      this.item.altImage = true;
      this.item.banner = item.image;
      console.log("[channelItemIDChange]",item,this.item);
    }
    setTimeout(() => {
      this.sendEvent.emit({event:'featured-config-course-change'})
    }, 100);
  }

}
