import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-col-left',
  templateUrl: './col-left.component.html'
})

export class ColLeftComponent {

  @Input() public layout: number;
  @Input() public section: string;

  @Output() public insertItemEvent = new EventEmitter<any>();
  @Output() public sectionChanged = new EventEmitter<any>();

  layoutComponentSelected: any;

  topComponentArray = [
    {
      component: 'header',
      label: 'Cabeçalho',
      icon: 'layout-sidebar-inset'
    }
  ];

  leftComponentArray = [
    {
      component: 'menu',
      label: 'Menu',
      icon: 'list'
    }
  ];

  mainComponentArray = [
    {
      component: 'feed',
      label: 'Feed',
      icon: 'rss'
    }
  ];

  rightComponentArray = [
    {
      component: 'search',
      label: 'Busca',
      icon: 'search'
    },
    {
      component: 'points',
      label: 'Pontos',
      icon: 'star-half'
    },
    {
      component: 'contributors',
      label: 'Colaboradores',
      icon: 'people'
    },
    {
      component: 'featured',
      label: 'Destaques',
      icon: 'bookmark'
    },
    {
      component: 'library',
      label: 'Bibliotecas',
      icon: 'collection'
    },
    {
      component: 'ecommerce-featured',
      label: 'Destaque (ecommerce)',
      icon: 'award'
    },
    {
      component: 'benefits',
      label: 'Benefícios',
      icon: 'gift'
    },
    {
      component: 'banner-link',
      label: 'Banner com link',
      icon: 'link'
    },
  ];


  setSection(section: string) {
    this.sectionChanged.emit(section);
  }

  insertItem(type: string) {
    this.insertItemEvent.emit(type);
  }

}
