import { NgModule } from '@angular/core';
import { ArrayGeneratePipe } from './array.pipe';
import { BgImagePipe } from './bgImage.pipe';
import { TextTruncatePipe } from './text-truncate.pipe';
import { AsapClientPipe } from './asap-client.pipe';

@NgModule({
  declarations: [
    ArrayGeneratePipe,
    BgImagePipe,
    TextTruncatePipe,
    AsapClientPipe
  ],
  exports: [
    ArrayGeneratePipe,
    BgImagePipe,
    TextTruncatePipe,
    AsapClientPipe
  ]
})

export class PipeModule { }
