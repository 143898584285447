import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-contributors',
  templateUrl: 'contributors.component.html'
})
export class ContributorsComponent {
  @Input() item: any;
}

