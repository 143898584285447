import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../@core/services/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})

export class RedirectComponent {
  token: string;
  trail: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public translate: TranslateService
  ) {
    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        this.token = params.token;
        this.trail = params.trail;
        this.authenticate();
      });
  }

  authenticate() {
    sessionStorage.setItem('apiURL', 'https://apiv3.plataformaasap.com.br');
    localStorage.setItem('apiURL', 'https://apiv3.plataformaasap.com.br');

    const data = {
      token: this.token
    };

    this.authService.setSessionToken(data)
      .then((response) => {
        const getLanguage = localStorage.getItem('language');
        var language = (getLanguage && getLanguage != 'undefined' && getLanguage != '')? localStorage.getItem('language') : 'pt-br';
        console.log('LANGUAGE SET ->', language);
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        this.router.navigate(['/']);
      });
  }

}
