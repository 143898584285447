import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-feed-config',
  templateUrl: 'feed-config.component.html'
})
export class FeedConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
