import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

    public static dictionary = {
        appName: 'Portal',
        favicon: 'assets/img/emblem-small.png',
        clientName: 'ASAP',
        welcome: 'Bem vindo',
        intro: 'Você está na Universidade',
        influencersParagraph: 'Você também pode ser um influenciador',
        loginBG: 'https://res.cloudinary.com/midia9-group/image/upload/v1562336393/image55399_anpfnb.png',
        logoLight: 'assets/img/logo.png',
        logoDark: 'assets/img/logo.png',
        logoFooter: 'assets/img/logo.png',
        logohelpdesk: 'assets/img/helpdesk.png',
        logoEAD: 'assets/img/logo.png',
        emblem: 'assets/img/emblem.png',
        bannerHome: 'assets/img/banner-home.jpg',
        bannerPage: 'assets/img/banner-home.jpg',
        bannerVoucher: 'assets/img/banner-home-beneficios.jpg',
        mainColor: '#f4ac1e',
        bgClient: 'white',
        bgSidebar: '#1f2123',
        linkSidebar: 'white',
        bgNavbar: '#1f2123',
        linkNavbar: 'white',
        bgFooter: '#1f2123',
        linkFooter: 'white',
        bgBtn: '#f4ac1e',
        textBtn: 'white',
        bgSidebarViewer: 'white',
        bgBtnViewer: '#f4ac1e',
        textBtnViewer: 'white',
        textBtnViewerActive: '#f4ac1e',
        primaryColor: '#f4ac1e',
        primaryColorHover: '#f4ac1e',
        supplementaryDataRequired: false,
        loginAD: false,
        firebase: null,
        documentsLayout: ''
    };

    public static setDictionary(dic) {
        for (const key in dic) {
            AsapClientPipe.dictionary[key] = dic[key];
        }
    }

    transform(value: any, args?: any): any {
        if (AsapClientPipe.dictionary[value] !== undefined) {
            return AsapClientPipe.dictionary[value];
        }
        return value;
    }

}
