import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-header-config',
  templateUrl: 'header-config.component.html',
  providers: [DataService]
})
export class HeaderConfigComponent implements OnInit {
  @Input() item: any;
  @Input() layout: any;
  @Input() customData: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadHeaderEvent = new EventEmitter<any>();

  trailData: any;
  eventData: any;
  videoData: any;

  featuredItem: any;
  featuredItemSearch: any;

  buffer: any;

  carouselItemActive: number;

  courseSectionData: any;
  eventSectionData: any;
  eventSections: any;

  constructor(
    private elRef: ElementRef,
    private dataService: DataService
  ) {
    this.carouselItemActive = 0;
  }

  ngOnInit() {
    this.getData();

    if (this.item.featureType === 'evento') {
      this.eventFeaturedChanged();
    }
    if (this.item.featureType === 'curso') {
      this.trailFeaturedChanged();
    }
    if (this.item.featureType === 'video') {
      this.videoFeaturedChanged();
    }
    this.buffer = this.item.featureType + '-' + this.item.featureId + '-' + this.item.featureDeadline;
  }

  setAlign(element: any, align: string): void {
    element = align;
  }

  setTitleAlign(align: string): void {
    this.item.titleAlign = align;
  }

  setSubtitleAlign(align: string): void {
    this.item.subtitleAlign = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadHeader() {
    this.buffer = this.item.featureType + '-' + this.item.featureId + '-' + this.item.featureDeadline;
    this.reloadHeaderEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.banner = $event.path;
  }

  getEmblemPhoto($event: any): void {
    this.item.emblem = $event.path;
  }

  getIconPhoto($event: any): void {
    this.item.icon = $event.path;
  }

  getVideo($event: any): void {
    this.item.video = $event.path;

    const player = this.elRef.nativeElement.querySelector('video');
    player.load();
  }

  carouselItemAdd() {
    if(!this.item.carousel) {
      this.item = {... this.item, carousel: []};
    }

    this.item.carousel.push({
      type: null,
      section: null,
      id: null,
    });

    this.carouselItemActive = this.item.carousel.length - 1;

  }

  getCarousel($event: any, index: number): void {
    this.item.carousel[index].path = $event.path;
  }

  carouselItemRemove(index: number): void {
    this.item.carousel.splice(index, 1);
    if (!this.item.carousel[this.carouselItemActive]) {
      this.carouselItemActive = this.item.carousel.length - 1;
    }

    this.sendTheEvent({event:'carousel-item-remove'})
  }

  getData() {
    const lu = sessionStorage.getItem('loggedUser');
    const user = JSON.parse(lu).data;

    this.dataService.web()
      .subscribe(res => {
        this.trailData = [];
        this.trailData.push(... res.data.trilhas);
        this.trailData.push(... res.data.influenciadores);
        this.trailData.push(... res.data.desenvolvimento);

        this.videoData = res.data.videos;
      });

    this.dataService.event(user.id)
      .subscribe(res => {
        this.eventData = res;
      });

    this.dataService.cursosSessao()
      .subscribe(res => {
        this.courseSectionData = res;
      });

    this.dataService.eventoSessao()
      .subscribe(res => {
        this.eventSections = res;
      });

    this.dataService.custom('event-sessao', 8)
      .subscribe((res: any) => {
        this.eventSectionData = res.data['evento-sessao'];
      });
  }

  courseBySession(section: any) {
    if (!this.courseSectionData) {
      return;
    }
    const find = this.courseSectionData.find((item: any) => +item.session_id === +section);
    if (find) {
      return find.trail;
    }
  }

  eventBySession(section: any) {
    if (!this.eventSectionData) {
      return;
    }
    const filter = this.eventSectionData.filter((item: any) => +item.session_id === +section);
    if (filter.length > 0) {
      return filter;
    }
  }

  sendTheEvent(event) {
    this.sendEvent.emit(event);
  }

  eventFeaturedChanged() {
    this.dataService.featured('evento', this.item.featureId).subscribe(res => {
      console.log('eventFeaturedChanged: res', res);
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  trailFeaturedChanged() {
    this.dataService.featured('curso', this.item.featureId).subscribe(res => {
      console.log('trailFeaturedChanged: res', res);
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  videoFeaturedChanged() {
    this.dataService.featured('video', this.item.featureId).subscribe(res => {
      console.log('videoFeaturedChanged: res', res);
      this.featuredItem = res.data.item[0];
      this.featuredItemSearch = res.data.itemSeach[0];
    });
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
