import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({name: 'bgImage'})
export class BgImagePipe implements PipeTransform {
  constructor(
    public sanitizer: DomSanitizer
  ) {

  }
  transform(image: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }
}
