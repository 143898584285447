import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'arrayGenerate'})
export class ArrayGeneratePipe implements PipeTransform {
    transform(count: number): Array<number> {
      const array = [];
      for (let i = 1; i <= count; i++) {
        array.push(i);
      }
      return array;
    }
}
