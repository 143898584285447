import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class BaseService {

  public host: string = environment.apiUrl;

  protected token = sessionStorage.getItem('token');
  protected headers = {
    'Content-Type': 'application/json',
    Authorization: this.token,
    'Access-Control-Allow-Origin': '*'
  };

  protected options = {
    headers: this.headers
  };

  constructor(
    protected http: HttpClient,
    protected basePath: string,
    protected entityName: string) {}

  getResources(params = {}) {
    return this.http.get(this.resourceUrl(null, params), this.options);
  }

  getResource(id: number, params = {}) {
    return this.http.get(this.resourceUrl(id, params), this.options);
  }

  createResource(data: any, params = {}) {
    return this.http.post(this.resourceUrl(null, params), JSON.stringify(data), this.options);
  }

  updateResource(data: any, params = {}) {
    return this.http.put(this.resourceUrl(data.id, params), JSON.stringify(data), this.options);
  }

  updateCustom(data: any, params = {}) {
    return this.http.put(this.resourceUrl(null, params), JSON.stringify(data), this.options);
  }

  deleteResource(id: number,  params = {}) {
    return this.http.delete(this.resourceUrl(id, params), this.options);
  }

  resourceUrl(id = null, params = {}) {

    const endpoint = [
      this.host,
      this.basePath,
      this.urlParameters(params),
      this.entityName,
      id
    ].filter(element => element != false).join('/').replace(/\/$/, '');

    let url = endpoint + this.queryString(params);

    let user: any = sessionStorage.getItem('loggedUser');
    if (user) {
      user = JSON.parse(user).data;
      if (url.indexOf('?') >= 0) {
        url = url + '&';
      } else {
        url = url + '?';
      }
      url = url + 'client_id=' + user.client_id;
    }

    return url;
  }

  protected urlParameters(params) {
    const urlParameters = [];

    for (const placeholder in params) {
      if (/.*_id$/.test(placeholder)) {
        urlParameters.push(`${placeholder}/${params[placeholder]}`);
      }
    }

    return urlParameters.join('/');
  }

  protected queryString(params) {
    let qry = '';

    if (params.router) {
      qry = `/${params.router}`;
    }

    if (params.query) {
      qry += `?${params.query}`;
    }

    return qry;
  }

}
