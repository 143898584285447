import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-library',
  templateUrl: 'library.component.html'
})
export class LibraryComponent {
  @Input() item: any;

  data: any;

  constructor() {
    this.data = [
      {

      }
    ]
  }

}

