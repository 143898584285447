import { isNgTemplate } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { DataService } from "src/app/@core/services/data.service";

@Injectable({providedIn: 'root'})
export class FeatureService {
  constructor(public dataService: DataService) {

  }

  getData(arrayList: any[]): Observable<any[]> {
    const observables = [];

    arrayList.forEach((item:any) => {
      if (item.type === 'banner-free') {
        observables.push(of({
          "id": -1,
          "type": item.type,
          "arquivo": item.banner,
          "url": item.url
        }))
      } else if(item.type === 'channel') {
        observables.push(this.getChannelItem(item.id));
      } else {
        observables.push(this.getFeaturedData(item.type, item.id));
      }
    });

    return forkJoin(observables)
      .pipe(
        map((responses, idx) => {
          return responses.map((item,idx) => {
            const banner = (arrayList[idx].altImage) ? arrayList[idx].banner : item.banner ;
            return {
              ... item,
              ...arrayList[idx],
              banner
            }
          });
        })
      );
  }

  getFeaturedData(type: string, id: string): Observable<any> {
    return this.dataService.featured(type, id)
      .pipe(
        map((response) => {
          if(response.data.itemSeach) {
            return response.data.itemSeach[0]
          }
          return {};
        })
      );
  }

  getChannelItem(id: string): Observable<any> {
    return this.dataService.channel(id);
  }
}
