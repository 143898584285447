import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-search',
  templateUrl: 'search.component.html'
})
export class SearchComponent {
  @Input() item: any;
}

