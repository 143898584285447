import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'app-select-dialog',
    templateUrl: './select-dialog.component.html',
    styleUrls: ['./select-dialog.component.css'],
    providers: [ ]
})
  
export class SelectDialogComponent  {
    
    public filter = "";
    public list;
    public filteredList = [];
    public multiselect = false;
    public multiselected = [];

    constructor(
        public dialogRef: MatDialogRef<SelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.list = this.data.searchList;
        this.filteredList = this.list;
        if (data.multiselect) {
            this.multiselect = data.multiselect;
        }
    }

    public doFilter() {
        this.filteredList = null;
        if (this.filter == "") {
            this.filteredList = this.list;
        }
        else {
            if (this.data.descriptionField) {
                this.filteredList = this.list.filter(item => item[this.data.descriptionField].toUpperCase().indexOf(this.filter.toUpperCase()) >= 0);
            }
            else {
                this.filteredList = this.list.filter(item => (item)?(item.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0):false);
            }
            console.log("Filtered List:",this.filteredList);
        }
    }
    
    public onNoClick(): void {
        this.dialogRef.close();
    }

    public unselect(item) {
        let index = this.multiselected.indexOf(item);
        if (index >= 0) {
            this.multiselected.splice(index,1);
        }
    }

    public select(item) {
        if (this.multiselected == item) {
            this.dialogRef.close(item);
        }
        else if (this.multiselect) {
            this.multiselected.push(item);
        }
        else {
            this.dialogRef.close(item);
        }
    }

}