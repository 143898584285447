import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})

export class TopMenuComponent  {
  @Output() public saveEvent = new EventEmitter<any>();

  closeWindow() {
    console.log('closeWindow');
    window.close();
  }

  save() {
    this.saveEvent.emit();
  }
}
