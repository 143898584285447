import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-header-featured-default',
  templateUrl: 'default.component.html'
})
export class HeaderFeaturedDefaultComponent {
  @Input() item: any;
  @Input() setting: any;
}
