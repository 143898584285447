import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-points',
  templateUrl: 'points.component.html'
})
export class PointsComponent {
  @Input() item: any;
}

