import { Component, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-col-right',
  templateUrl: './col-right.component.html'
})

export class ColRightComponent {
  @Input() item: any;
  @Input() section: any;
  @Input() layoutComponentSelected: any;
  @Input() settings: any;
  @Input() customData: any;
  @Input() layout: any;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public saveConfigEvent = new EventEmitter<any>();


  constructor(
  ) {

  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  setElementAlign(element: any, align: string) {
    element = align;
  }

  emitSendEvent(event) {
    this.sendEvent.emit(event);
  }

  saveConfig() {
    this.saveConfigEvent.emit();
  }

  getVideoArchive($event: { path: string }) {
    this.item.source_path = $event.path;
  }

  getPhoto($event: { path: string }) {
    this.item.url = $event.path;
  }
}
