import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-feed',
  templateUrl: 'feed.component.html'
})
export class FeedComponent {
  @Input() item: any;
}

