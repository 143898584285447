import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type Channel = {
  id: string;
  banner: string;
  name: string;
}

@Injectable({providedIn: 'root'})
export class DataService {
  constructor(public http: HttpClient) {
  }

  menu(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/menu/view', this.header);
  }

  event(userId: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/event/unit/' + userId + '?type=presential', this.header);
  }

  web(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/geral/web', this.header);
  }

  lives(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/event/live/gallery', this.header);
  }

  custom(type: string, role: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/geral/custom?type=' + type + '&role=' + role, this.header);
  }

  acervo() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/acervo/pasta/abre/permission', this.header);
  }

  color(id: number) {
    return this.http.get(environment.baseUrl + '/api/v2/admin/color/' + id, this.header);
  }

  folders(type: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder?role=' + type, this.header);
  }

  videoFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/video/pasta', this.header);
  }

  videoInFolder(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/video/' + folder, this.header);
  }

  eventFolders() {
    return this.http.get(environment.baseUrl + '/api/v2/client/event-folder', this.header);
  }

  eventsInFolder(folder: number): Observable<any> {
    const query = '?page=1&type=presential&client_id=' + this.client + '&folder=' + folder;
    return this.http.get(environment.baseUrl + '/api/v2/client/event' + query, this.header);
  }

  trailInFolder(folder: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder/' + folder + '/items?page=0', this.header);
  }

  notices() {
    return this.http.get(environment.baseUrl + '/api/v2/client/edital/influenciadores', this.header);
  }

  journey() {
    return this.http.get(environment.baseUrl + '/api/v21/admin/jornada', this.header);
  }

  journeyItens(id: number) {
    return this.http.get(environment.baseUrl + '/api/v21/admin/jornada/item/client/' + id, this.header);
  }

  productByFeatured() {
    const uri = environment.baseUrl + '/api/v2/client/produto/mais/vendidos/' + this.client + '?client_id=' + this.client;
    return this.http.get(uri, this.header);
  }

  storeByFeatured() {
    const uri = environment.baseUrl + '/api/v2/client/loja/abre/' + this.client + '/destaque?client_id=' + this.client;
    return this.http.get(uri, this.header);
  }

  featured(type: string, id: string): Observable<any> {

    if (type === 'course-section') {
      type = 'curso';
    }
    if (type === 'event-section') {
      type = 'evento';
    }
    if (type === 'video-section') {
      type = 'video';
    }

    return this.http.get(environment.baseUrl + '/api/v2/client/itens/custom?type=' + type + '&id=' + id, this.header);
  }

  eventoSessao(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v21/admin/eventos/session?client_id=' + this.client, this.header);
  }

  eventsInSession() {
    return this.http.get(environment.baseUrl + '/api/v21/admin/eventos/session?client_id=' + this.client, this.header);
  }

  cursosSessao(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/session/geral/web?client_id=' + this.client, this.header);
  }

  benefits() {
    return this.http.get(environment.baseUrl + '/api/v2/client/beneficio/geral/web', this.header);
  }

  channels(): Observable<Channel[]> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/channel?type=recommended&client_id=' + this.client, this.header)
      .pipe(
        map((response: {data: Channel[]}) => response.data)
      );
  }

  channelItems(id,type): Observable<Channel[]> {
    let queryString = '';
    if (type == 'image') {
      type = 'file';
      queryString = '&type=image';
    }
    return this.http.get(environment.baseUrl + `/api/v2/admin/channel/${type}/${id}?client_id=${this.client}${queryString}`, this.header)
      .pipe(
        map((response: {data: Channel[]}) => response.data)
      );
  }

  channel(id: string): Observable<Channel> {
    const url = `${environment.baseUrl}/api/v2/admin/channel/${id}?client_id=${this.client}`;
    return this.http.get(url, this.header)
      .pipe(
        map((response: {data: Channel}) => response.data)
      );
  }

  get client(): number {
    const lu = sessionStorage.getItem('loggedUser');
    const user = JSON.parse(lu).data;

    return user.client_id;
  }

  get header(): {headers: HttpHeaders} {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
