import { Component, Input, OnChanges, OnInit, ViewChildren } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit, OnChanges {

  @ViewChildren('component') components;

  @Input() item: any;
  @Input() settings: any;
  @Input() layout: number;

  now = new Date();

  lastitemData: any;
  featuredData: any;

  showFeatured: boolean;
  featureLoading: boolean;

  constructor(
    private dataService: DataService
  ) {
    this.featureLoading = true;
  }

  ngOnChanges(changes: any) {
    console.log('HeaderComponent', changes);
  }

  ngOnInit(): void {
    this.getFeaturedData();
  }

  reload() {
    this.featureLoading = true;
    this.getFeaturedData();
  }

  getFeaturedData() {
    if (this.item.layout === 'feature') {
      this.checkShowFeatured(this.item.featureDeadline);
      this.dataService.featured(this.item.featureType, this.item.featureId).subscribe(res => {
        console.log('HeaderComponent: getFeaturedData: res', res);
        this.lastitemData = res.data.item[0];
        this.featuredData = res.data.itemSeach[0];
        this.featureLoading = false;
      });
    }
  }

  checkShowFeatured(deadline: any): void {
    const now = this.now.getTime();
    const dead = new Date(deadline).getTime();

    console.log('HeaderComponent: checkShowFeatured', now, dead);

    this.showFeatured = (dead > now) ? true : false;
  }

  processEvent(event=null) {
    this.components.forEach(component => {
      if (component.processEvent) {
        component.processEvent(event);
      }
    });
  }


}
