import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-menu-config',
  templateUrl: 'menu-config.component.html'
})
export class MenuConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Input() accordion: any;
  @Output() public sendEvent = new EventEmitter<any>();

  data: any;

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit(this.data);
  }

  
}
