import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';

import { MaterialDesignModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';

import { FeedComponent } from './feed/feed.component';
import { FeedConfigComponent } from './feed/config/feed-config.component';
import { MenuConfigComponent } from './menu/config/menu-config.component';
import { MenuComponent } from './menu/menu.component';
import { FeaturedComponent } from './featured/featured.component';
import { FeaturedConfigComponent } from './featured/config/featured-config.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { ContributorsConfigComponent } from './contributors/config/contributors-config.component';
import { LibraryComponent } from './library/library.component';
import { LibraryConfigComponent } from './library/config/library-config.component';
import { PointsComponent } from './points/points.component';
import { PointsConfigComponent } from './points/config/points-config.component';
import { SearchComponent } from './search/search.component';
import { SearchConfigComponent } from './search/config/search-config.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DirectiveModule } from '../@core/directives/directive.module';
import { HeaderFeaturedDefaultComponent } from './header/featured/default/default.component';
import { HeaderFeaturedEventComponent } from './header/featured/event/event.component';
import { HeaderFeaturedTrailComponent } from './header/featured/trail/trail.component';
import { HeaderFeaturedVideoComponent } from './header/featured/video/video.component';
import { HeaderConfigComponent } from './header/config/header-config.component';
import { HeaderComponent } from './header/header.component';
import { EcommerceFeaturedComponent } from './ecommerce-featured/ecommerce-featured.component';
import { EcommerceFeaturedConfigComponent } from './ecommerce-featured/config/ecommerce-featured-config.component';
import { BenefitsConfigComponent } from './benefits/config/benefits-config.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { BannerLinkComponent } from './banner-link/banner-link.component';
import { BannerLinkConfigComponent } from './banner-link/config/banner-link-config.component';
import { HeaderCarouselComponent } from './header/carousel/carousel.component';
import { SharedFeaturedConfigComponent } from './shared/featured/config/featured-config.component';

const components = [
  FeedComponent,
  FeedConfigComponent,
  MenuComponent,
  MenuConfigComponent,
  FeaturedComponent,
  FeaturedConfigComponent,
  ContributorsComponent,
  ContributorsConfigComponent,
  LibraryComponent,
  LibraryConfigComponent,
  PointsComponent,
  PointsConfigComponent,
  SearchComponent,
  SearchConfigComponent,
  HeaderComponent,
  HeaderConfigComponent,
  HeaderCarouselComponent,
  HeaderFeaturedVideoComponent,
  HeaderFeaturedTrailComponent,
  HeaderFeaturedEventComponent,
  HeaderFeaturedDefaultComponent,
  EcommerceFeaturedComponent,
  EcommerceFeaturedConfigComponent,
  BenefitsComponent,
  BenefitsConfigComponent,
  BannerLinkComponent,
  BannerLinkConfigComponent,
  SharedFeaturedConfigComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
    TagInputModule,
    ColorPickerModule,
    MaterialDesignModule,
    SharedModule,
    DirectiveModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})

export class ComponentsModule { }
