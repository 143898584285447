import { Component, OnInit, ViewChildren } from '@angular/core';
import { LayoutService } from '../@core/services/layout.service';
import Swal from 'sweetalert2';

import { DataService } from '../@core/services/data.service';
import { AsapClientPipe } from '../@core/pipes/asap-client.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {

  @ViewChildren('component') components;

  id: number;
  item: any;
  trailStageSelected: any;
  settings: any;
  layout: number;
  styleZoom: number;
  accordion: any;

  customData: any;

  data: any;

  public leftComponentArray: Array<any>;
  public mainComponentArray: Array<any>;
  public rightComponentArray: Array<any>;
  public topComponentArray: Array<any>;

  public itemSelected: any;
  public status: string;
  componentLoading = true;

  section: string;

  layoutComponentSelected: string;

  constructor(
    private layoutService: LayoutService,
    private dataService: DataService
  ) {
    this.section = 'left';

    if (localStorage.getItem('stage_zoom')) {
      this.styleZoom = +localStorage.getItem('stage_zoom');
    } else {
      this.styleZoom = 0.6;
    }

    this.customData = {
      primaryColor: '#333333',
      secondaryColor: '#333333',
      greyColor: '#AAAAAA'
    };
  }

  ngOnInit() {
    this.status = '';
    this.getColor();
  }

  getCustomData() {

    this.layoutService.getResources({ query: 'type=1' })
      .subscribe((res: any) => {
        if (res.data && res.data.value) {
          this.leftComponentArray = res.data.value.left;
          this.mainComponentArray = res.data.value.main;
          this.rightComponentArray = res.data.value.right;

          this.topComponentArray = res.data.value.top || [];
        } else {
          this.leftComponentArray = [];
          this.mainComponentArray = [];
          this.rightComponentArray = [];
          this.topComponentArray = [];
        }
        this.componentLoading = false;
      });

  }

  processEvent(event=null) {
    console.log("[processEvent]",event);
    if (!event) {
      this.save();
    }
    else {
      this.components.forEach(component => {
        if (component.processEvent) {
          component.processEvent(event);
        }
      });
    }
  }

  save() {
    const data = {
      left: this.leftComponentArray,
      main: this.mainComponentArray,
      right: this.rightComponentArray,
      top: this.topComponentArray
    };

    Swal.fire('Sucesso', 'Salvo com sucesso!', 'success');

    this.layoutService.createResource({ value: data }, { query: 'type=1' })
      .subscribe((res: any) => {
        console.log('saved: data', data);
      });
  }

  insertItem(event: string) {

    const findTopComponent = this.topComponentArray.find(i => i.type === event);

    if (findTopComponent) {
      this.selectItem(findTopComponent);
      return;
    }

    const findLeftComponent = this.leftComponentArray.find(i => i.type === event);

    if (findLeftComponent) {
      this.selectItem(findLeftComponent);
      return;
    }

    const findMainComponent = this.mainComponentArray.find(i => i.type === event);

    if (findMainComponent) {
      this.selectItem(findMainComponent);
      return;
    }

    // const findRightComponent = this.rightComponentArray.find(i => i.type === event);

    // if (findRightComponent) {
    //   this.selectItem(findRightComponent);
    //   return;
    // }

    if (event === 'header') {
      this.topComponentArray.push({
        type: 'header',
        layout: 'texto',
        background: '#eeeeee',
        color: '#222222',
        titleText: 'SEJAM BEM-VINDOS',
        titleColor: '#222222',
        titleAlign: 'center',
        titleSize: 32,
        paddingBottom: 30,
        paddingTop: 30,
        carousel: []
      });
      this.lastSelect('top');
    }

    if (event === 'feed') {
      this.mainComponentArray.push({
        type: 'feed'
      });
      this.lastSelect('main');
    }

    if (event === 'menu') {
      this.leftComponentArray.push({
        type: 'menu'
      });
      this.lastSelect('left');
    }

    if (event === 'contributors') {
      this.rightComponentArray.push({
        type: 'contributors',
        marginTop: 0,
        marginBottom: 30,
        titleText: 'Colaboradores',
        titleBackground: this.customData.primaryColor,
        titleColor: '#ffffff',
        buttonText: 'ver todos',
        buttonBackground: this.customData.primaryColor,
        buttonColor: '#ffffff'
      });
      this.lastSelect('right');
    }

    if (event === 'featured') {
      this.rightComponentArray.push({
        type: 'featured',
        marginTop: 0,
        marginBottom: 30,
        titleText: 'CURSOS',
        titleBackground: this.customData.primaryColor,
        titleColor: '#ffffff',
        cardTitleColor: this.customData.primaryColor
      });
      this.lastSelect('right');
    }

    if (event === 'library') {
      this.rightComponentArray.push({
        type: 'library',
        marginTop: 0,
        marginBottom: 30,
        coverStyle: 'imageFull',
        titleBackground: this.customData.primaryColor,
        titleColor: '#ffffff',
        titleLibrary: 'BIBLIOTECA',
        titleChannel: 'CANAIS'
      });
      this.lastSelect('right');
    }

    if (event === 'points') {
      this.rightComponentArray.push({
        type: 'points',
        marginTop: 0,
        marginBottom: 30,
        buttonBackground: this.customData.primaryColor,
        buttonColor: '#ffffff',
        featuredColor: this.customData.primaryColor
      });
      this.lastSelect('right');
    }

    if (event === 'search') {
      this.rightComponentArray.push({
        type: 'search',
        marginTop: 0,
        marginBottom: 30
      });
      this.lastSelect('right');
    }

    if (event === 'ecommerce-featured') {
      this.rightComponentArray.push({
        type: 'ecommerce-featured',
        marginTop: 0,
        marginBottom: 30
      });
      this.lastSelect('right');
    }

    if (event === 'benefits') {
      this.rightComponentArray.push({
        type: 'benefits',
        marginTop: 0,
        marginBottom: 30
      });
      this.lastSelect('right');
    }

    if (event === 'banner-link') {
      this.rightComponentArray.push({
        type: 'banner-link',
        image: '',
        external_link: '',
        marginTop: 0,
        marginBottom: 30
      });
      this.lastSelect('right');
    }
  }

  lastSelect(type: string): void {
    if (type === 'top') {
      this.selectItem(this.topComponentArray[this.topComponentArray.length - 1]);
    }

    if (type === 'left') {
      this.selectItem(this.leftComponentArray[this.leftComponentArray.length - 1]);
    }

    if (type === 'main') {
      this.selectItem(this.mainComponentArray[this.mainComponentArray.length - 1]);
    }

    if (type === 'right') {
      this.selectItem(this.rightComponentArray[this.rightComponentArray.length - 1]);
    }
  }

  selectItem(item: any) {
    this.itemSelected = item;

    this.leftComponentArray.map(i => this.reset(i));
    this.mainComponentArray.map(i => this.reset(i));
    this.rightComponentArray.map(i => this.reset(i));
    this.topComponentArray.map(i => this.reset(i));

    this.itemSelected.active = true;
  }

  reset(i: any) {
    i.active = false;
  }

  delete(type: string, item: any) {
    const types = {
      left: this.leftComponentArray,
      main: this.mainComponentArray,
      right: this.rightComponentArray,
      top: this.topComponentArray
    };

    const typeFound = types[type];

    const index = typeFound.indexOf(item);
    typeFound.splice(index, 1);
  }


  move(target: string, actualIndex: number, newIndex: number) {

    const types = {
      left: this.leftComponentArray,
      main: this.mainComponentArray,
      right: this.rightComponentArray,
      top: this.topComponentArray
    };

    const typeFound = types[target];

    while (actualIndex < 0) {
      actualIndex += typeFound.length;
    }
    while (newIndex < 0) {
      newIndex += typeFound.length;
    }
    if (newIndex >= typeFound.length) {
      let k = newIndex - typeFound.length;
      while ((k--) + 1) {
        typeFound.push(undefined);
      }
    }
    typeFound.splice(newIndex, 0, typeFound.splice(actualIndex, 1)[0]);
  }

  setStyleZoom(zoom: number) {
    localStorage.setItem('stage_zoom', zoom.toString());
    this.styleZoom = zoom;
  }

  getColor() {
    const lu = sessionStorage.getItem('loggedUser');
    const user = JSON.parse(lu).data;
    this.dataService.color(user.client_id).subscribe((response: any) => {
      console.log('HomeComponent: getColor: response', response);
      const dictionary = response.data.config;
      if (dictionary) {
        this.settings = dictionary;
        AsapClientPipe.setDictionary(dictionary);
        this.setHtmlData();
      }
      this.getCustomData();
    });
  }

  setHtmlData() {
    const html = document.querySelector('html');

    html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);

    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

    html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
    html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);

    html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
    html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

    html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
    html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

    html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
    html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);

    html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
    html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.mainColor);
    html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnViewer);
    html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.textBtnViewer);
    html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.textBtnViewerActive);

    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

    html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
    html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);

    html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);
  }


}
