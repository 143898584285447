import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-header-featured-video',
  templateUrl: 'video.component.html'
})
export class HeaderFeaturedVideoComponent {
  @Input() item: any;
  @Input() setting: any;
}
