import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';

@Injectable({providedIn: 'root'})
export class LayoutService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http, 'api/v2/client', 'type/layout');
  }

}
