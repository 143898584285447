import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-ecommerce-featured',
  templateUrl: 'ecommerce-featured.component.html'
})
export class EcommerceFeaturedComponent {
  @Input() item: any;
}

